import React from "react";
import SpiceSensations from "./SpiceSensations";
import Tales_of_tea from "./tales_of_tea";
import SoyaChunk from "./Soya_Chunks";

function MainOurproduct() {
  return (
    <div className="main_tales_of_tea_bg_image_full_page">
      <Tales_of_tea />
      <SpiceSensations /> 
      <SoyaChunk />
    </div>
  ); 
}

export default MainOurproduct;
