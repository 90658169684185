import React from "react"
import "../../style/contact-us/contact-us-location.css"

function Mylocation() {
	return (
		<>
			<div className="location-contact-us">
				<div>
					{/* <iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.2564174914964!2d77.2751049!3d28.5320115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce158d84ad6ff%3A0x3cd24cc76c4cdb42!2sSaburi%20Tea%20Estate!5e0!3m2!1sen!2sin!4v1722043686471!5m2!1sen!2sin"
						width="600"
						height="450"
						style="border:0;"
						allowfullscreen=""
						loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"
					></iframe> */}
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.2564174914964!2d77.2751049!3d28.5320115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce158d84ad6ff%3A0x3cd24cc76c4cdb42!2sSaburi%20Tea%20Estate!5e0!3m2!1sen!2sin!4v1722043686471!5m2!1sen!2sin"
						//   width="600"
						//   height="450"
						//   style="border:0;"
						allowfullscreen="true"
						loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"
						className="location-iframe"
						title="our_location"
					></iframe>
				</div>
			</div>
		</>
	)
}

export default Mylocation
