import React from 'react'
import Aboutheader from '../components/about/aboutHeader'
import AboutSaburi from '../components/about/aboutSaburi'
import Footer from "../components/footer"
function About() {
  return (
    <div>
        <Aboutheader/>
        <AboutSaburi/>
        <Footer/>
    </div>
  )
}

export default About