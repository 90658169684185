import React from "react";
import "../../style/about/AboutSaburi.css";
import left_Leaf from "../../images/our-product-img/leaf_left.png";
import Right_Leaf from "../../images/our-product-img/leaf_right.png";
import Left_small_leaf from "../../images/About/left_small_leaf.png";
import Right_small_leaf from "../../images/About/right_small_leaf.png";

function AboutSaburi() {
  return (
    <>
      <div>
        <div className="main_about_saburi_bg_image">
          {/* side images Leafs */}
          <div className=" d-flex justify-content-between">
            <div>
              <img src={left_Leaf} alt="Icon" className="" srcset="" />
            </div>
            <div>
              <img src={Right_Leaf} alt="Icon" className="" srcset="" />
            </div>
          </div>
          {/* side images Leafs end */}
          {/* upper text */}
          <div
            className="main_tales_text_section"
            data-aos="zoom-in"
            data-aos-offset="200"
            data-aos-duration="900"
          >
            {/* tales of tea text */}
            <div className="main_About_saburi_section">
              <h2 className="main_tales_of_tea_heading">About Saburi</h2>
            </div>
            <div className="About_saburi_section_message_parent" >
              <p className="About_saburi_section_message ">
                Saburi is a distinguished team of experts dedicated to
                delivering exceptional products. Committed to perfection, we
                ensure the highest standards in safety, hygiene, and taste. Our
                FSSAI-approved offerings encompass a range of premium products,
                including Tea, Spices, Elaichi, Laung, Jeera, Ajwain, Saunf, and
                Black Pepper. Trusted in Delhi and Haryana, we are expanding our
                presence to include markets in Punjab, Rajasthan, and Uttar
                Pradesh. Experience excellence with Saburi, where quality meets
                commitment.
              </p>
            </div>
          </div>
          {/* upper text end */}

          {/* side small images Leafs */}
          <div className=" d-flex justify-content-between mt-5 ">
            <div>
              <img src={Left_small_leaf} alt="Icon" className="corner_icon" srcset="" />
            </div>
            <div>
              <div className="main_About_saburi_section">
                <h2 className="main_about_heading">Business Specification</h2>
              </div>
            </div>
            <div>
              <img src={Right_small_leaf} alt="Icon" className="corner_icon" srcset="" />
            </div>
          </div>
          {/* side small images Leafs */}

          {/* card  */}
          {/* 1  for small screen*/}
          <div className="card_for_small_screen" >
            {/* 1 */}
            {/* tales of tea card section */}
            <div className="main_tales_card_div">
              {/* card 1 */}
              <div
                className="about_saburi_card_first about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div>Nature of Business</div>
                <div className="about_saburi_first_card_message">
                  Manufacture, Trader, Wholesaler/Distributor and supplier
                </div>
              </div>
              {/* card 2 */}
              <div
                className="about_saburi_card_second about_saburi_card"
                data-aos="flip-down"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div className="about_saburi_second_card_message">
                  Year of Establishment
                </div>
                <div>2008</div>
              </div>
            </div>
            {/* tales of tea card section end */}
            {/* side small images Leafs */}
            <div className=" d-flex justify-content-between ">
              <div>
                <img src={Left_small_leaf} alt="Icon" srcset="" />
              </div>
              <div>
                <img src={Right_small_leaf} alt="Icon" srcset="" />
              </div>
            </div>
            {/* side small images Leafs */}
            {/* 2 */}
            {/* tales of tea card section */}
            <div className="main_tales_card_div ">
              {/* card 1 */}
              <div
                className="about_saburi_card_first about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div>No. of Employees</div>
                <div className="about_saburi_first_card_message">
                  As per the requirement
                </div>
              </div>
              {/* card 2 */}
              <div
                className="about_saburi_card_second about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div className="about_saburi_second_card_message">
                  No. of Production Unit
                </div>
                <div>1</div>
              </div>
            </div>
            {/* tales of tea card section end */}
            {/* side small images Leafs */}
            <div className=" d-flex justify-content-between">
              <div>
                <img src={Left_small_leaf} alt="Icon" srcset="" />
              </div>
              <div>
                <img src={Right_small_leaf} alt="Icon" srcset="" />
              </div>
            </div>
            {/* side small images Leafs */}
            {/* 3 */}
            {/* tales of tea card section */}
            <div className="main_tales_card_div ">
              {/* card 1 */}
              <div
                className="about_saburi_card_first about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div>Monthly Production Capacity</div>
                <div className="about_saburi_first_card_message">
                  As per the requirement
                </div>
              </div>
              {/* card 2 */}
              <div
                className="about_saburi_card_second about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div className="about_saburi_second_card_message">
                  Production Type
                </div>
                <div>Semi-Automatic</div>
              </div>
            </div>
            {/* tales of tea card section end */}
            {/* side small images Leafs */}
            <div className=" d-flex justify-content-between ">
              <div>
                <img src={Left_small_leaf} alt="Icon" srcset="" />
              </div>
              <div>
                <img src={Right_small_leaf} alt="Icon" srcset="" />
              </div>
            </div>
            {/* side small images Leafs */}
            {/* 4 */}
            {/* tales of tea card section */}
            <div className="main_tales_card_div ">
              {/* card 1 */}
              <div
                className="about_saburi_card_first about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div>Warehouse Facility</div>
                <div className="about_saburi_first_card_message">Yes</div>
              </div>
              {/* card 2 */}
              <div
                className="about_saburi_card_second about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div className="about_saburi_second_card_message">
                  Original Equipment Manufacture
                </div>
                <div>Yes</div>
              </div>
            </div>
            {/* tales of tea card section end */}
            {/* side small images Leafs */}
            <div className=" d-flex justify-content-between">
              <div>
                <img src={Left_small_leaf} alt="Icon" srcset="" />
              </div>
              <div>
                <img src={Right_small_leaf} alt="Icon" srcset="" />
              </div>
            </div>
            {/* side small images Leafs */}
            {/* 5 */}
            {/* tales of tea card section */}
            <div className="main_tales_card_div ">
              {/* card 1 */}
              <div
                className="about_saburi_card_first about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div>Bankers</div>
                <div className="about_saburi_first_card_message">
                  Allahabad Bank, Kotak Mahindra Bank
                </div>
              </div>
            </div>
            {/* tales of tea card section end */}
          </div>

          {/* 2 for big screen */}
          <div className="card_for_big_screen" >
            {/* tales of tea card section */}
            <div className="main_tales_card_div">
              {/* card 1 */}
              <div
                className="about_saburi_card_first about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div>Nature of Business</div>
                <div className="about_saburi_first_card_message">
                  Manufacture, Trader, Wholesaler/Distributor and supplier
                </div>
              </div>
              {/* card 2 */}
              <div
                className="about_saburi_card_second about_saburi_card"
                data-aos="flip-down"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div className="about_saburi_second_card_message">
                  Year of Establishment
                </div>
                <div>2008</div>
              </div>
              {/* tales of tea card section end */}
              {/* 2 */}
              <div
                className="about_saburi_card_first about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div>No. of Employees</div>
                <div className="about_saburi_first_card_message">
                  As per the requirement
                </div>
              </div>
              {/* card 2 */}
              <div
                className="about_saburi_card_second about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div className="about_saburi_second_card_message">
                  No. of Production Unit
                </div>
                <div>1</div>
              </div>
              {/* 3 */}
              <div
                className="about_saburi_card_first about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div>Monthly Production Capacity</div>
                <div className="about_saburi_first_card_message">
                  As per the requirement
                </div>
              </div>
              {/* card 2 */}
              <div
                className="about_saburi_card_second about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div className="about_saburi_second_card_message">
                  Production Type
                </div>
                <div>Semi-Automatic</div>
              </div>
              {/* 4 */}
              <div
                className="about_saburi_card_first about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div>Warehouse Facility</div>
                <div className="about_saburi_first_card_message">Yes</div>
              </div>
              {/* card 2 */}
              <div
                className="about_saburi_card_second about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div className="about_saburi_second_card_message">
                  Original Equipment Manufacture
                </div>
                <div>Yes</div>
              </div>
              {/* 5 */}
              <div
                className="about_saburi_card_first about_saburi_card"
                data-aos="flip-up"
                data-aos-offset="200"
                data-aos-duration="900"
              >
                <div>Bankers</div>
                <div className="about_saburi_first_card_message">
                  Allahabad Bank, Kotak Mahindra Bank
                </div>
              </div>
            </div>
          </div>

          {/* side images Leafs */}
          <div className=" d-flex justify-content-between">
            <div>
              <img src={left_Leaf} alt="Icon" className="corner_icon" srcset="" />
            </div>
            <div>
              <img src={Right_Leaf} alt="Icon" className="corner_icon" srcset="" />
            </div>
          </div>
          {/* side images Leafs end */}
        </div>
      </div>
    </>
  );
}

export default AboutSaburi;
